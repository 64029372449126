/* raleway-300italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
       url('../fonts/Raleway/raleway-v13-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Raleway/raleway-v13-latin-300italic.woff') format('woff'), /* Modern Browsers */
      
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  
  src: local('Raleway'), local('Raleway-Regular'),
       url('../fonts/Raleway/raleway-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Raleway/raleway-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
    
}

/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway Bold'), local('Raleway-Bold'),
       url('../fonts/Raleway/raleway-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Raleway/raleway-v13-latin-700.woff') format('woff'), /* Modern Browsers */
   
}

