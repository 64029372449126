body {
  background: white;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 1.0rem;
  color: $grau2;
  line-height: 1.6rem;
}
section {
  // height:100vh;
  margin-left: 0;
  overflow-y: hidden; // scroll;
  // padding-top abhängig von navi höhe _header.html
  padding-top: 150px;
  @media(max-width:576px){
    padding-right: 5px;
    padding-top:50px;
  }
  @media (min-width: 768px) {
    min-height: 600px;
  }
  @media (min-width: 992px) {
    min-height: 800px;
  }
 }
#start{
  @media(min-width: 1200px){
   height:100vh;
  }
}
#main-navbar {
  border-bottom: thin solid $chp-blau;
  background: rgba(255, 255, 255, 0.95)
}

#kontakt{
  padding-top:100px;
}
#footer{
  margin-top:50px;
  margin-bottom:50px;
  @media(min-width:992px){
    margin-top:100px;
    margin-bottom:50px;
  }
}
img{
 
  @media(max-width:576px){
    padding-bottom:10px;
  }
 }
 
ul {
  padding-left: 15px;
  li {
    padding-bottom: 10px;
  }
}
.biga {
  padding-left: 0;
}

.mt5 {
  margin-top: 5px;
}
.mt-50 {
  margin-top: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-50{
  padding-bottom:50px;
}
a {
  font-weight: 400;
  font-size: 1.2rem;
  // outline: none;
  color: $chp-blau;
  &:hover {
    color: $chp-blaulight;
  }
}
ul.plc {
  padding-left: 60px;
}

// Navbar

nav{
 background:white;
 margin-bottom:25px;
}

.nav-link{
 color: black;
 text-decoration:none;
 &.active{
   color: $chp-blau;
   padding-bottom: 2px;
   border-bottom: 2px solid $chp-blaulight;
 }
 &:hover{
  color: $chp-blau;
   padding-bottom:2px;
   border-bottom: 2px solid $chp-blaulight;
 }
 &:active{
   
    color:$chp-blau;
 }
 &:focus{
    color:$chp-blau;
 }
}
.navbar-toggler{
  background-color: $chp-blau;
}

// kleine bilder in reihe
.img-row {
  transition: all 0.4s ease;
  &:hover {
    transform: scale(1.1);
  }
  margin-left: 3rem;
  &:first-child {
    margin-left: 15px;
    padding-left: 0;
  }
  @media (max-width: 576px) {
    padding-bottom: 10px;
    // bei den Kleinen definiert first-child
    // den Abstand von links für alle
    &:first-child {
      margin-left: 50px;
    }
  }
  @media (min-width: 577px) AND (max-width: 768px) {
    padding: 0 10px 10px 0;
    &:first-child {
      margin: auto;
    }
  }
  @media (min-width: 769px) AND (max-width: 992px) {
    padding: 0 10px 10px 0;
    &:first-child {
      margin: auto;
    }
  }
  @media (min-width: 993px) AND (max-width: 1024px) {
    padding: 0 10px 10px 0;
    &:first-child {
      margin: auto;
    }
  }
}

/// responsive Tabs!!

.nav-tabs {
  display: none;
}

@media (min-width: 768px) {
  .nav-tabs {
    display: flex;
    .nav-link:hover{
     color: green;
      border-bottom: 1px solid $grau3;
      border-radius: 3px;
      
      
      
    }
  }
 
  .card {
    border: none;
  }
  
  

  .card .card-header {
    display: none;
  }

  

  .card .collapse {
    display: block;
    
  }
}

@media (max-width: 767px) {
  /*
     * Changed selector to `.tab-content > .tab-pane` to override `.fade:not(.show)`
     */
  .tab-content > .tab-pane {
    display: block;
    opacity: 1;
  }
  .card-header {
    padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.125);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        color: $grau2;
    
    }
    .card-header :hover {
      text-decoration: none;
      color: orange;
    }
}

#tabs {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  border-bottom: none;
  
  
}
#tabs a {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 1.0em;
  
  
  
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link .nav-link:hover  {

  color: $grau2;
  background-color: $grau3;
  border-radius: 5px;
 
 

  &.active{
    color: $grau2;}
    &:before {
      font-family: "FontAwesome";
      font-weight:400;
      content: "\f111";
      display:inline-block;
      // font-style: normal;
    font-size: 0.4em;
      padding-right: 0.6em;
    color: orange;
      -webkit-font-smoothing: antialiased;}
     
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  color: $grau2;
  margin:8px 0 8px 0;
 
}
h1 {
  font-size: 1.9em;
}
h2 {
  font-size: 1.6em;
}
h3 {
  font-size: 1.3em;
}
h4 {
  font-size: 1.0em;
}
h5 {
  font-size: 0.7em;
}
h6 {
  font-size: 0.4em;
}
 h1.start {
   text-align:center;
 }

.mt {
  margin-top: 20px;
}
.fs {
  font-size: 1.2em;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  
}
.fss {
  font-size: 0.85em;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  color: $grau2;
}
.k {
  font-size: 1.0em;
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  color: $grau; 
}

a.aufzu {
  color: $chp-blau;
  font-weight:300;
  text-decoration:none;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: $chp-blaulight;
  }
  &:before {
    position:relative;
    top:3px;
    padding-right:3px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(4,55,147)' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }
  &.auf:before {
    position:relative;
    top:3px;
    padding-right:3px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(4,55,147)' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }
} 

a.mail {
  color: $chp-blau;
  text-decoration:none;
  &:hover{
    color:$chp-blaulight;
  }
  &:before{
    position:relative;
    padding-right:3px;
    top:3px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(4,55,147)' class='bi bi-envelope' viewBox='0 0 16 16'%3E%3Cpath d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z'/%3E%3C/svg%3E");
  }
  &:hover::before{
    padding-right:3px;
   content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(53,122,250)' class='bi bi-envelope' viewBox='0 0 16 16'%3E%3Cpath d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z'/%3E%3C/svg%3E");

  }
}

a.pdf {
  color: $chp-blau;
  text-decoration:none;
  &:hover{
    color:$chp-blaulight;
  }
  &:before{
    padding-right:3px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(4,55,147)' class='bi bi-file-earmark-pdf-fill' viewBox='0 0 16 16'%3E%3Cpath d='M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z'/%3E%3Cpath fill-rule='evenodd' d='M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z'/%3E%3C/svg%3E");
  }
  &:hover::before{
   padding-right:3px;
   content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(53,122,250)' class='bi bi-file-earmark-pdf-fill' viewBox='0 0 16 16'%3E%3Cpath d='M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z'/%3E%3Cpath fill-rule='evenodd' d='M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z'/%3E%3C/svg%3E");

  }
}

a.ex {
  color: $chp-blau;
  text-decoration:none;
  &:hover{
    color:$chp-blaulight;
  }
  &:before{
    padding-right:3px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(4,55,147)' class='bi bi-box-arrow-up-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
  }
  &:hover::before{
   padding-right:3px;
   content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(53,122,250)' class='bi bi-box-arrow-up-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");

  }
}
p {
  margin: 5px 0 5px 0;
}

.bt{
  border-top: 1px solid $grau3;
}
#karte {
  min-width: 200px;
  min-height: 300px;
}
@media(min-width:801px) {
  #karte {
    height: 520px;
    border: 1px solid orange;
  }
}


// neu chp 01 2022

//.portrait, .video{
  //margin-top:20px;
  

// border left blue
.blb{
   border-left: 2px solid ($chp-hellblau);
   padding-left:15px;
   padding-right:50px;
}
.vita{
  padding: 5px;
  border-radius: 5px;
 // background: transparentize($chp-blau, 0.9);
  padding: 5px;
  //border-radius: 5px;
  //margin-top: 20px;
   padding-left:40px;
 }
